div.loading-modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    h4 {
        color: white;
        font-size: 1.5em;
    }
    .modal-content {
        background-color: transparent;
        flex-direction: row;
        border: none;
        align-content: center;
    }
}