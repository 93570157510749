$primex-blue:#00345A;
$primex-orange:#DA8141;
$primex-silver:#A1958B;
$primex-tan: #D7C692;
$primex-maroon:#922D46;

$bg-lt-beige:rgb(234, 229,225);
$bg-lter-beige:rgb(244, 241, 239);
$bg-lt-blue:#CBD5E3;
$bg-lt-yellow:#F5EFE3;

$text-black:#383739;
$link-color: $text-black;

$theme-colors: (
    "primary": $primex-orange,
    "secondary": $primex-blue
);
      
@import "~bootstrap/scss/bootstrap.scss";
@import './global.scss';
@import './header.scss';
@import './sideNav.scss';
@import './dashboard.scss';
@import './layout.scss';
@import './fonts.scss';
@import './questionnaire.scss';
@import './cyberThreat.scss';
@import './members.scss';
@import './training.scss';
@import './howto.scss';
@import './contentCreation.scss';
@import './footer.scss';
@import './notifications.scss';
@import './globalMessage.scss';
@import './postsAndArticles.scss';
@import './documents.scss';
@import './checklists.scss';
@import './cyberClaims.scss';
@import './loadingModal.scss';
