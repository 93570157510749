div.layout-row {
    margin-left: 0;
    margin-right: 0;
    min-height: calc(100vh - 206px);
    div.col,
    [class*="col-*"] {
        padding-left: 0;
        padding-right: 0;
    }
    div.side-nav-container {
        width: 150px;
        @media print {
            display: none !important;
        }
    }
    div.layout-children {
        background-color: $bg-lt-beige;
    }
    a.primex-return {
        right: 0;
        position: absolute;
        margin: 1.25em 1em;
    }
}

div.system-alert {
    background-color: $bg-lt-beige;
    padding: 1rem 1rem;
    margin-bottom: .5rem;
    > p {
        margin-bottom: 0;
        > strong {
            font-family: 'freight-sans-bold';
        }
    }
}