div.footer {
  color: $primex-blue;
  font-family: 'freight-sans-book';
  padding: 1rem;
  div.empty-div {
    flex-grow: 1;
  }
  @media print {
    display: none !important;
  }
}