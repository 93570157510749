.members-dashboard {
  padding: 2rem;
  .member-container {
    padding: 1.5rem;
    
    div.filter-row {
      display: flex;
      justify-content: space-around;
      margin-top: 1rem;
      label.row-title {
        align-self: flex-end;
        margin-bottom: 1rem;
      }
      div.form-group {
        flex-grow: 1;
        margin-left: .5rem;
        margin-right: .5rem;
      }
    }

    div.profile-upper-row {
      margin: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 1px solid $bg-lt-beige;
      div.top-item {
        padding: 2rem;
      }
      div.maturity-dropdown {
        max-width: 200px;
      }
    }

    div.profile-bottom-row {
      margin: 1rem;
      div.member-item-group {
        padding: 2rem;
        div.member-item {
          margin-bottom: 1rem;
        }
      }
    }
  }
}