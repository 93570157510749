div.global-header {
    color: $primex-blue;
    font-family: 'freight-sans-bold';
    .alertDropdown::after {
        display: none;
    }
    div.how-to-link-container {
        border-right: 1px solid grey;
        height: 100%;
        img {
            padding-left: .75rem;
            padding-right: .75rem;
            padding-top: .375rem;
            padding-bottom: .375rem;
        }
    }
    div.alertDropdownContainer {
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        height: 100%;
        .bell-icon {
            font-size: 24px;
            color: $primex-blue;
        }
        .alertDropdown > span.badge {
            border-radius: .5rem;
            position: absolute;
            top: 0;
            right: 0;
        }
        .dropdown-menu-center {
            right: auto !important;
            left: 50% !important;
            top: 100% !important;
            -webkit-transform: translate(-50%, 0) !important;
            -o-transform: translate(-50%, 0) !important;
            transform: translate(-50%, 0) !important;
        }
        .dropdown-header,
        .dropdown-footer {
            background-color: #333333;
            color: white;
            a {
                color: white;
                text-decoration: none;
            }
        }
        .dropdown-footer {
            text-align: center;
            padding: .5rem;
        }
        .no-notifications {
            min-width: 300px;
            min-height: 50px;
        }
    }
    .member-button > button {
        padding-left: 0;
        font-weight: 600;
        color: $primex-blue;
    }
    .dropdown-header,
    .dropdown-footer,
    .dropdown-item {
        font-family: 'freight-sans-book';
        font-size: 14px;
        text-decoration: none;
    }
    .light-grey-background {
        background-color: #f6f6f6;
    }
    .text-underline {
        text-decoration: underline;
    }
    @media print {
        display: none !important;
    }

}

