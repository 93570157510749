.how-to-container {
  padding: 1.5rem;
  .accordion {
    .card {
      div.card-body {
        padding-top: 0;
        ul.how-to-topic-slide-list {
          list-style: none;
          padding: 0;
          li:not(:first-child) {
            margin-top: .5rem;
          }
        }
      }
    }
  }

  .text-underline {
    text-decoration: underline;
  }
}
.button-link,
.button-link:hover,
.button-link:focus,
.btn.btn-primary.button-link:active {
  background-color: inherit;
  border: none;
  text-decoration: underline;
  color: #212529;
}

div.how-to-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;

  img {
    display: block;
    max-width: 100% !important;
    margin-inline: auto;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  .modal-dialog {
    width: 100%;
  }
  .modal-content {
    height: 85vh;
  }
  .modal-header {

    border-bottom: none;
    border-bottom: 1px solid $primex-blue;
    align-items: center;
    justify-content: space-between;
    .close {
      margin-left:10px;
    }
  }
  .modal-body {
    overflow: scroll;
    overflow-x: hidden;
    padding: 2rem;
    width: 100%;
  }
  .modal-info{
    margin-top: auto;
    margin-bottom: auto;
    padding: 1rem;
  }
  .modal-footer {
    background-color: $primex-blue;
    button {
      min-width: 100px
    }
    .pagination-icon {
      color: white;
      margin-left: .25rem;
      margin-right: .25rem;
      &.active {
        color: $primex-orange;
      }
    }
  }
}
div.create-how-to-toast {
  .toast-header {
    background-color: $primex-orange;
    color: white;
    button.close {
      color: white !important;
      opacity: .9;
    }
  }
}