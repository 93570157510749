 button#claims-outline-button,
 button.claims-outline-button {
   border: 1px solid $primex-blue;
   color: $primex-blue;
   align-items: center;
 }

 .cyber-claims-container {
   padding: 1.5rem;

   .select-dropdown {
     width: 200px;
   }

   img.member-access-icon {
    height: 20px;
   }

   div.claim-info-container {
     margin-top: 2em;
     padding: 1em;

    .header {
      p {
        font-size: large;
      }
    }
    p {
      font-family: lato;
      font-size: 14px;
    }

    span.arrow-line {
      border-right: 2px solid #212529;
      height: 5px;
    }
    .claim-date {
      font-family: lato;
    }
    textarea {
      height: auto;
      width: 100%;
      resize: none;
    }
   }

   .dashed {
     border: dashed;
   }

   .file-upload {
     color: $primex-orange;
     border: 1px solid $primex-orange;
   }
   .file-upload-ir > input#formFile {
    font-size: 18px;
    display: block;
    width: 100%;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    &:focus {
        outline: none;
      }
    }

   img.file-tab-icon {
     min-width: 0;
     width: 15px;
   }

   span {
     color: $primex-blue;

     &.orange {
       color: $primex-orange;
       text-decoration: underline;

       &:hover {
         cursor: pointer;
       }
     }
   }

   div.updates-attention {
     padding: 1.5rem;
     background-color: $primex-blue;
     color: white !important;

     p {
       margin: 0;
       font-size: large;
       color: inherit;
     }

     span {
       color: inherit;
       font-family: 'freight-sans-bold';
       font-size: large;
     }
   }

   p.claim-info-subtitle {
     font-size: large;
     font-weight: 600;
     margin-bottom: 1px;
   }

   .info-icon {
     cursor: pointer;
   }

   div.updates-comments {

     table.table-bordered {
       thead {
         background-color: #eae5e1;
       }

       td,
       th {
         vertical-align: middle;
         border-left: none;
         border-right: none;
         padding: .5rem;
       }


       .text-underline {
         text-decoration: underline;
         cursor: pointer;
       }
     }
   }
 }

 div.member-access-buttons {
  button {
    min-width: 125px;
  }
 }
 div.cyber-info-toast {
  .toast-header {
    background-color: $primex-orange;
    color: white;
    button.close {
      opacity: .9;
      span {
        color: white;
      }
    }
  }
  position: absolute;
  background-color: rgb(255, 255, 255);
  right: 0;
  z-index: 2;
 }

 div.nav-row {
  border-bottom: 1px solid #dee2e6;

  div.nav-item {
      a {
          margin-bottom: -1px;
          border: 1px solid transparent;
          border-top-left-radius: .25rem;
          border-top-right-radius: .25rem;
          padding: .75rem 2rem;

          &.active {
              border-color: #dee2e6 #dee2e6 #fff;
              background-color: #fff;
              text-decoration: none;
              pointer-events: none;
          }
      }
  }
  button.btn {
      padding: .2rem .75rem;
      margin-bottom: 8px;
  }
} 
