div.dashboard-container {
    background-color: $bg-lt-beige;
    padding: 2rem;
    .dashboard-title {
        margin: 0 1rem;
    }
    .card-section {
        margin: 1rem;
        a {
            font-size: 16px;
            font-weight: normal;
        }
    }
    .report-cyber-card {
        text-align: center;
        p {
            font-family: Lato-bold;
            color: $primex-orange;
            font-size: xx-large;
        }
    }
    .cyber-threat-card {
        table {
            border: 2px solid $bg-lt-beige;
            thead {
                background-color: $bg-lt-beige;
            }
            td {
                vertical-align: middle;
            }
        }
    }
    .checklist-dash-card {
        h5 {
            font-family: lato;
            font-size: 1rem;
        }
    }
    .recommended-training-card {
        .training-link {
            border: 2px solid $bg-lt-beige;
            color: #212529;
            margin-bottom: .5rem;
            div {
                margin: .75rem;
                p {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }

    .questionnaire-dash-card {
        .questionnaire-link {
            border: 2px solid $bg-lt-beige;
            border-top-width: 8px;
            color: #212529;
            margin-bottom: .5rem;
            div.progress-bar {
                background-color: $primex-blue;
            }
            & > div {
                margin-top: .75rem;
                margin-bottom: .75rem;
                p {
                    padding: 0;
                    margin: 0;
                }
            }
        }
    }
    div.questionnaire-multi-dropdown {
        button.dropdown-toggle {
            background-color: #fff;
            border: none;
            color: #212529;
            padding-top: 0;
            padding-bottom: 0;
            text-align: center !important;

            &::after {
                display: none;
            }
        }
    }
    .assigned-training-card {
        p {
            font-family: "Lato-Bold";
        }
    }

    .billboard-card {
        div.billboard-image {
            max-width: 100%;
            height: auto;
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
    @media print {
        background-color: white !important;
    }
}