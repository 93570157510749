.documents-container {
  padding: 1.5rem;
  .filter-title {
    min-width: 60px;
  }
  .button-container {
    min-width: 160px;
  }
  .select-dropdown {
    width: 100%;
  }
  button.button-link {
    background-color: inherit;
    border: none;
    text-decoration: underline;
    color: #212529;
  }
  .input-container {
    position: relative;
    min-height: 200px;
    border: 1px $primex-silver dashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .file-upload > input#formFile {
        font-size: 18px;
        display: block;
        width: 100%;
        border: none;
        text-transform: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;

        
        &:focus {
            outline: none;
        }
    }
    .browse {
        text-decoration: underline;
        font-family:'freight-sans-medium';
        &:hover {
            cursor: pointer;
        }
    }
    button.delete-file,
    button.delete-file.btn-primary:active,
    button.delete-file.btn-primary:focus {
        background-color: #fff;
        border: none;
        img {
            height: 50px;
        }
    }
    
  }
}
div.docUploadModal {
  div.member-select-card {
      div.card-header {
          background-color: white;
      }
      table.table-bordered.member-select {
          margin-bottom: 0;
      }
  }

}