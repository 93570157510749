div.posts-articles-table-container {
  padding: 1.5rem;
}

div.post-article-body {
  button {
    @media print {
      display: none !important;
    }
  }
  div.global-message-container {
    background-color: $bg-lt-beige;
  }
}

div.latest-post {
  img.article-thumbnail {
    max-height: 250px;
    height: auto;
    width: 100%;
  }
}

div.post-carousel {
  .pagination-icon {
    opacity: .65;
    margin-left: .25rem;
    margin-right: .25rem;
    &.active {
      opacity: 1;
      color: $primex-orange;
    }
  }
  button.btn.btn-secondary.carousel-button {
    display: inline-flex;
    align-items: center;
    margin: 0 5px;
    justify-content: center;
    padding: 0;
    height: 20px;
    width: 20px;
    border-radius: 0;
  }
  div.empty-div {
    width: 150px;
  }
}