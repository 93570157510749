div.view-checklist {
  div.checklist-items {
    div.checklist-item {
      div.checklist-item-header {
        background-color: white;
        border-bottom: 0;
      }
    }
  }
  div.footer-box {
    div.user-attachments {
      button.btn-primary:disabled {
        color: inherit;
        background-color: inherit;
        border-color: inherit;
      }
      div.form-group {
        margin-bottom: 0;
      }
    }
    div.complete-box {
      align-self: flex-end;
      button {
        border-radius: 50px;
      }
    }
  }
}

div.checklists-table-container {
  padding: 1.5rem;
}