.cyberthreat-container {
  padding: 1.5rem;
  div.nav-row {
    border-bottom: 1px solid #dee2e6;
    div.nav-item {
      a {
        margin-bottom: -1px;
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        padding: .75rem 2rem;
        &.active {
          border-color: #dee2e6 #dee2e6 #fff;
          background-color: #fff;
  
        }
      }
    }
    button.btn {
      padding: .2rem .75rem;
      margin-bottom: 8px;
    }
  }
  button.button-link {
    background-color: inherit;
    border: none;
    text-decoration: underline;
    color: #212529;
    text-align: left;
  }
  div.published-container,
  div.threat-detail-admin-container {
    div.searchbar {
      max-width: 100%;
      input {
        border-right: none;
      }
    }
    span.input-group-text {
      background-color: #fff;
      border-left: none;
    }
  }
}

div.incoming-alert-modal {
  table {
    tr {
      td {
        word-break: break-word;
        padding: .5rem;
      }
    }
  }
}

div.mega-modal {
  div.modal-dialog {
    max-width: 95%;
    div.quill-box {
      div.ql-container,
      div.ql-editor {
        height: 300px;
      }
    }
    div.alert-box {
      max-height: 1000px;
      overflow-y: scroll;
      border: 1px black solid;
      padding: 1rem;
    }
  }
  button.btn {
    min-width: 120px;
  }
}
div.not-mega-modal {
  div.quill-box {
    div.ql-container,
    div.ql-editor {
      height: 300px;
    }
  }
}
button.btn.no-button,
button.btn.btn-primary.no-button:active,
button.btn.btn-primary.no-button:focus {
    background-color: inherit;
    border: none;
    color: #212529;
    box-shadow: none;
}

div.message-background {
  background-color: $bg-lt-beige;
}
.cyber-threat-card {
  td.cyber-card-title {
      max-width: 25vw;
    button.button-link {
      background-color: inherit;
      border: none;
      text-decoration: underline;
      color: #212529;
      text-align: left;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      
  }
  }
}