body {
    color: $text-black;
    font-family: 'freight-sans-book', 'freighttext-book-regular', 'lato';
}

h1,
h2,
h3 {
    font-family: 'freight-sans-bold';
    color: $primex-blue;

    &.silver {
        color: $primex-silver;
    }
}

h4,
h5 {
    font-family: 'freighttext-bold-regular';
    color: $text-black;

    &.silver {
        color: $primex-silver;
    }
}

h1 {
    font-size: 29px;
}

h2 {
    font-size: 23px;
}

h3,
h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

p {
    color: $text-black;
    word-spacing: 1px;
}

div.row-no-gutters {
    margin-left: 0;
    margin-right: 0;

    >.col,
    >[class*=col-] {
        padding-left: 0;
        padding-right: 0;
    }
}

a.btn:not(.button-link),
button:not(.button-link) {
    font-family: 'freight-sans-bold';
    text-decoration: none;
}

div.multi-select-dropdown,
.numeric-font,
table>* {
    font-family: 'lato' !important;
}

a {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none !important;
}

.bold {
    font-family: 'freight-sans-bold';
}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

table.table-bordered {
    border: 1px solid $primex-silver;
    border-collapse: collapse;
    thead {
        background-color: #F4F1EF;
    }

    th {
        font-family: "Lato-Bold";
        font-size: 16px;
        color: $text-black;

        span,
        button {
            vertical-align: middle;
            min-width: 0 !important;
        }
    }

    td,
    th {
        border: 1px solid $primex-silver;
        // min-width: 100px;
        border-left: none;
        border-right: none;
        &.check {
            min-width: 0;
            width: 15px !important;
        }

        vertical-align: middle;
        left: 0;
        padding: .5rem;

        div.dropdown {
            button.dropdown-toggle {
                background-color: #fff;
                border: none;
                color: #212529;
                padding-top: 0;
                padding-bottom: 0;
                text-align: center !important;

                &::after {
                    display: none;
                }
            }
        }
    }
    th.check-box,
    td.check-box {
        min-width: 0 !important;
        width: 5%;
        text-align: center;
    }
    td.img {
        text-align: center;
    }
    
    .text-underline {
        text-decoration: underline;
        cursor: pointer;
    }
    img.finished-icon,
    img.unfinished-icon {
        height: 25px;
    }
}


p.publish-tags {
    border-radius: 1000px;
    background-color: $bg-lt-blue;
    padding: 5px 10px;
    margin: 10px;
    font-family: "lato-bold";
  }

p.character-count {
    font-family: "lato";
    font-size: small;
    font-family: "Lato-Bold";
    font-size: 16px;
    text-align: center;
}

label.form-label {
    font-family: "freight-sans-bold";
    font-size: 16px;
}

div.accordion {
    .card-header {
        width: 100%;
        background-color: #F4F1EF;
    }
}

a {
    img {
        color: $text-black;
    }
}

div.custom-dropdown {
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

div.ql-editor {
    padding-inline: 0;
}

span.number-font {
    font-family: lato;
}

.page-link{
    text-decoration: none;
}