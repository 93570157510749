.content-creation-container {
  div[class*="col-"] {
    div.card {
      height: 100%;
    }
  }
  .button-box {
    button {
      min-width: 150px;
    }
  }
  div.manager-container {
    padding: 1.5rem;
    button {
      min-width: 100px;
    }
    div.searchbar {
      max-width: 100%;
      input {
        border-right: none;
      }
    }
    span.input-group-text {
      background-color: #fff;
      border-left: none;
    }
  }
  div.card {
    height: 100%;
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    }
}

div.how-to-creation-container {
  div.create-slide-container {
    div.create-slide-item {
      div.item-accordion {
        width: 90%;
          div.input-group {
            width: 90%;
          }
      }
      div.quill-box-content {
        div.quill-box {
          div.ql-container {
            min-height: 200px;
            div.ql-editor {
              min-height: 200px;
            }
            border: 0;
          }
          div.ql-toolbar {
            border-left: 0;
            border-right: 0;
            background-color: $bg-lt-beige;
          }
        }
      }
    }
  }
}

div.checklist-creation-container {
  div.create-slide-item {
    div.item-accordion {
      width: 90%;
        div.input-group {
          width: 90%;
        }
      }
  }
}
div.user-attachments,
div.create-post-article,
div.create-billboard {
  label.form-label {
    font-family: 'freighttext-bold-regular';
  }
  button.upload-file-button {
    position: relative;
    display: flex;
    background-color: white;
    color: $primex-orange;
    border-color: $primex-orange;
    &:hover,
    &:active,
    &:focus {
      background-color: white !important;
      border-color: $primex-orange !important;
      color: $primex-orange !important;
    }
    div.custom-file {
      position: absolute;
      div.invalid-feedback {
        margin-left: 80px;
        margin-top: -15px;
        text-align: left;
      }
    }
    div.custom-file > input#banner-file-upload {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}

div.billboard-preview {
  .billboard-card {
    div.billboard-image {
        max-width: 100%;
        height: auto;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}
}


div.quill-box-content {
  div.quill-box {
    div.ql-container,
    div.ql-editor {
      min-height: 200px;
    }
  }
}

div.post-article-admin-container {
  div.border-row {
    border: 1px solid $bg-lt-beige;
  }
  div.searchbar {
    max-width: 100%;
    input {
      border-right: none;
    }
  }
  span.input-group-text {
    background-color: #fff;
    border-left: none;
  }
}