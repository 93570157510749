.questionnaire-container {
    min-height: 50vh;
    div.questionnaire-multi-dropdown {
        button.dropdown-toggle {
            background-color: #fff;
            border: none;
            color: #212529;
            padding-top: 0;
            padding-bottom: 0;
            text-align: center !important;

            &::after {
                display: none;
            }
        }
    }
}

.input-container {
    position: relative;
    min-height: 200px;
    border: 1px $primex-silver dashed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .file-upload > input#formFile {
        font-size: 18px;
        display: block;
        width: 100%;
        border: none;
        text-transform: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;

        
        &:focus {
            outline: none;
        }
    }
    .browse {
        text-decoration: underline;
        font-family:'freight-sans-medium';
        &:hover {
            cursor: pointer;
        }
    }
    button.delete-file,
    button.delete-file.btn-primary:active,
    button.delete-file.btn-primary:focus {
        background-color: #fff;
        border: none;
        img {
            height: 50px;
        }
    }

}

div.uploaded-modal {
    img.uploaded-icon {
        height: 35px;
    }
}

.questionnaire-session-card {
    margin-top: 2rem;
    
    ul.questionnaire-categories {
        list-style: none;
        padding: 0;
        // border: 1px solid $bg-lt-beige;
        display: flex;
        flex-direction: column;
        height: 100%;
        li {
            flex-grow: 1;
            border: 1px solid $bg-lt-beige;
            background-color: $bg-lter-beige;
            padding: 1rem;
            text-align: center;
            display: flex;
            justify-content: center;
            p {
                margin: 0;
                padding: 0;
                align-self: center;
            }
            &.active {
                background-color: white;
                border-right: none;
               & > p {
                    font-family: 'freight-sans-medium';
                }
            }
        }
    }

    div.question-section {
        border-top: 1px solid $bg-lt-beige;
        border-bottom: 1px solid $bg-lt-beige;
        border-right: 1px solid $bg-lt-beige;
        border-left: none;

        div.form-group {
            div.form-check {
                padding-top: 1rem;
            }
        }

        div.questionnaire-button-group {
            button {
                border-radius: 1000px;
                min-width: 175px;
                .buttonArrow {
                    font-size: 20px;
                }
            }
        }
    }
}

button.question-info-button,
button.question-info-button:hover,
button.question-info-button:focus,
button.question-info-button:active {
    color: $primex-orange;
    text-decoration: underline;
    font-size: small;
    font-family: lato;
    padding: 0;
    &.btn-primary,
    &.btn-primary:not(:disabled) {
        background-color: transparent;
        border-color: transparent;

    }
}

.responsive-iframe_wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

div.admin-container {
    div.border-section {
        border: 1px solid $bg-lt-beige;
        padding: .5rem;
    }
    div.searchbar {
      max-width: 100%;
      input {
        border-right: none;
      }
    }
    span.input-group-text {
      background-color: #fff;
      border-left: none;
    }
}

div.question-info-modal {
    div.modal-content {
        min-height: 400px;
    }
}

.member-select-table {
    &.table-bordered {
        th, td {
            border-left: none;
            border-right: none;
        }

        th {
            border-bottom: 0;
        }
    }

    // table row grouping styling
    &.table-bordered tr.group-top td {
        // this is needed instead of a border which gets hidden due to the
        // border-collapse of the parent table
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: black;
        }
        // border-top: 1px solid black !important;
    }
  
    &.table-bordered tr.group-bottom td {
        border-bottom: 1px solid black !important;
    }
    
    &.table-bordered tr.group-row td:first-child {
        border-left: 1px solid black !important;
    }
    
    &.table-bordered tr.group-row td:last-child {
        border-right: 1px solid black !important;
    }
}

div.intro-modal {
    min-height: 400px;
}
div#questionnaire-submit {
    div.modal-content {
        background-color: transparent;
        border: none;
    }

    div#questionnaire-loading {
        font-family: Lato-Bold;
        font-size: x-large;
        color: white;
        text-align: center;
    }
}

div.questionnaire-toast {
    position: absolute;
    top: 50px;
    right: 10px;
    z-index: 1;
    min-width: 300px;
    font-family: lato;
    div.toast-header button {
        text-align: right;
        width: 100%;
    }
}

