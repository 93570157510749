@font-face {
    font-family: 'freighttext-book-regular';
    src: url('../assets/fonts/FreightText\ Book\ Regular.ttf');
}

@font-face {
    font-family: "freighttext-medium-regular";
    src: url('../assets/fonts/FreightText\ Medium\ Regular.ttf');
}

@font-face {
    font-family: 'freighttext-bold-regular';
    src: url("../assets/fonts/FreightText\ Bold\ Regular.ttf");
}

@font-face {
    font-family: 'freight-sans-book';
    src: url("../assets/fonts/Freight\ Sans\ Book.otf");
}

@font-face {
    font-family: 'freight-sans-medium';
    src: url('../assets/fonts/Freight\ Sans\ Medium.otf');
}

@font-face {
    font-family: 'freight-sans-bold';
    src: url('../assets/fonts/Freight\ Sans\ Bold.otf');
}
@font-face {
    font-family: 'lato';
    src: url('../assets/fonts/Lato-Regular.ttf');
}

@font-face {
    font-family: 'Lato-Bold';
    src: url('../assets/fonts/Lato-Bold.ttf');
}
@font-face {
    font-family: 'Lato-Black';
    src: url('../assets/fonts/Lato-Black.ttf');
}