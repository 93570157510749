div.side-nav-column {
    background-color: $primex-blue;
    height: 100%;
    width: 100%;
    ul.side-nav-list {
        list-style: none;
        padding: 0;
        li {
            display: flex;
            justify-content: center;
            .side-nav-item {
                text-decoration: none;
                width: 100%;
                padding: .75rem 1.5rem;
                background-color: $primex-blue;
                text-align: center;
                img {
                    max-width: 100px;
                }
                p {
                    color: white;
                    text-align: center;
                    margin: 0;
                }
                &.active {
                    background-color: $primex-orange;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
    div.security-logo-container {
        padding: .75rem 1.5rem;
    }
}
